<!--会长寄语-->
<template>
  <div class="messageDiv">
    <!--面包屑-->
    <div class="breadTopDiv">
      <el-row class="breadTop">
        <el-col>
          <top-path-list :path-obj="pathObj"></top-path-list>
        </el-col>
      </el-row>
    </div>
    <!--内容部分-->
    <div class="content-div">
      <div class="titleDiv">
        <el-row>
          <el-col :span="24">
            <span class="news_title">会长寄语</span>
          </el-col>
        </el-row>
      </div>
      <el-row>
        <el-col :span="8">
          <img src="@/assets/img/president.png" alt="">
        </el-col>
        <el-col :span="16">
          <p style="text-indent: 2em;">
            在全国上下加快推进供给侧改革，促进新旧动能转换，加快经济转型升级之际，山东省地理标志产业协会顺势而动，应时而生，在美丽的泉城济南正式成立，这是所有致力于地理标志事业、从事地理标志产业志士仁人的一件大事，是全省经济发展、社会组织建设不断发展壮大的一件喜事。
          </p>
          <p style="text-indent: 2em;">
            党的二十大会议的胜利召开，选举了新一届国家领导人，政府工作报告提出了一系列重大举措，为地理标志产业发展指明了前进的方向，绘就了蓝图，依然备受鼓舞、心潮澎湃、激动万分。我们带领全体会员单位按照国家既定的方针政策，乘势乡村振兴、知识产权强国和沿黄河重大国家战略，带领本行业踔厉奋发、砥砺前行，为打造好“齐鲁样板”这个品牌，实现全省地理标志产业高质量发展贡献力量。
          </p>
          <p style="text-indent: 2em;">
            近四十年的改革开放，推动经济社会大发展大提升，也推动了对中华民族瑰宝和历史传承文化的保护发展，地理标志产品就是闪烁在齐鲁大地上的奇葩。随着农村经济发展，品牌建设不断推进，对地理标志产业的发展也得到了各级政府及企业重视，得到了社会各界和广大人民群众的关注。山东是我国经济和农业大省，具有独特的地理环境和悠久的地域文化，名优产品和特色资源众多，孕育了一大批具有独特品质、声誉和历史文化的地理标志产品，成为我国拥有地理标志产品最多的省份。这些堪称国宝省典的优秀地理标志产品，经过一代代齐鲁人的精心打造、智慧创造成为代表中国、代表山东的闪亮“名片”成为我们的自豪和骄傲。
          </p>
        </el-col>
      </el-row>
      <el-row>
        <p style="text-indent: 2em;">
          岁月不居，天道酬勤。文化厚重、人杰地灵的山东涌现出了一大批敢为人先、奋发拼搏、勇立潮头的经济社会风云人物和各行各业的领头羊，他们在事业发展、成绩斐然的今天，以强烈的民族精神和社会责任感，以对家乡、对这片土地深深的热爱情怀，积极投身我省地理标志产业伟大事业中，以实际行动助推地理标志产业发展迎来了新的春天。顺应广大地理标志产品专业社团、经济组织及从事地理标志产业的企业和专业人士的愿望需求，由山东方宇商标事务所有限公司、地理标志产品知名专业社团等多家单位联合发起结成了全省性行业协会社会组织，经省民政部门依法审核批准成立“山东省地理标志产业协会”。协会的成立得到了全省各地地理标志产品生产、经营和关联行业的积极响应和拥护，得到了各级各有关部门、院校、团体、机构的大力支持和悉心指导。在此，山东地理标志产业协会向关心关注支持的各位领导、各界朋友表示最衷心的感谢！
        </p>
        <p>
          扬帆起航的山东省地理标志产业协会，在新时代的召唤下，踏向新征程，奔向灿烂的新明天！
        </p>
      </el-row>
    </div>
  </div>
</template>

<script>
import topPathList from '@/components/topPathList'
import {getPageByCategoryId, getDetailByd} from '@/utils/commonApi.js'
export default {
  name: "message",
  components: { topPathList},
  data() {
    return {
      pathObj: [
        {
          name: '首页',
          path: '/'
        },
        {
          name: '地标协会',

        },
        {
          name: '会长寄语',
          path: '/giia/message'
        },
      ],
      title: '会长寄语',
      newsData: '',
      time: '',
      contentId: '',
      labelList: '',
    }
  },
  methods: {
    //根据栏目id查询文章列表
    getList() {
      let params = {
        ...this.$route.query,
        current: 1,
        size: 10,
      }
      getPageByCategoryId(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.contentId = res.data.data.records[0].contentId
          this.getDetail()
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    },

    //根据文章id查询文章详情
    getDetail() {
      let params = {
        contentId: this.contentId
      }
      getDetailByd(params).then(res => {
        if (res.data.code === 10000 || res.data.code === 200) {
          this.title = res.data.data.contentTitle //标题
          this.time = res.data.data.contentEditTime?.substring(0,10) //日期
          this.newsData = res.data.data.contentDetail //内容
          this.labelList = res.data.data.keyword //文章标签
        } else {
          this.$message.warning("请求失败")
        }
      }).catch(err => {
        this.$message.error('请求失败')
      })
    }
  },
  mounted() {
    this.getList()
    /*this.newsData = `
    <div class="temp_font">在全国上下加快推进供给侧改革，促进新旧动能转换，加快经济转型升级之际，山东省地理标志产业协会顺势而动，应时而生，在美丽的泉城济南正式成立，这是所有致力于地理标志事业、从事地理标志产业志士仁人的一件大事，是全省经济发展、社会组织建设不断发展壮大的一件喜事。</div>
    <div class="temp_font">党的二十大会议的胜利召开，选举了新一届国家领导人，政府工作报告提出了一系列重大举措，为地理标志产业发展指明了前进的方向，绘就了蓝图，依然备受鼓舞、心潮澎拜、激动万分。我们带领全体会员单位按照国家既定的方针政策，乘势乡村振兴、知识产权强国和沿黄河重大国家战略，带领本行业踔厉奋发、砥砺前行，为打造好“齐鲁样板”这个品牌，实现全省地理标志产业高质量发展贡献力量。
</div>
    <div class="temp_font">近四十年的改革开放，推动经济社会大发展大提升，也推动了对中华民族瑰宝和历史传承文化的保护发展，地理标志产品就是闪烁在齐鲁大地上的奇葩。随着农村经济发展，品牌建设不断推进，对地理标志产业的发展也得到了各级政府及企业重视，得到了社会各界和广大人民群众的关注。山东是我国经济和农业大省，具有独特的地理环境和悠久的地域文化，名优产品和特色资源众多，孕育了一大批具有独特品质、声誉和历史文化的地理标志产品，成为我国拥有地理标志产品最多的省份。这些堪称国宝省典的优秀地理标志产品，经过一代代齐鲁人的精心打造、智慧创造成为代表中国、代表山东的闪亮“名片”成为我们的自豪和骄傲。
</div>
    <div class="temp_font">5月10日，调研组首先在枣庄市市场监管局与相关负责同志就枣庄市地理标志工作情况、地理标志“十四五”发展规划的工作目标、工作内容进行了座谈交流。枣庄市市场监督管理局二级调研员甘信石、运用促进科科长邵长胜、知识产保护科科长周广柱以及知识产保护科副科长殷金保同志参与座谈。</div>
    <div class="temp_font">岁月不居，天道酬勤。文化厚重、人杰地灵的山东涌现出了一大批敢为人先、奋发拼搏、勇立潮头的经济社会风云人物和各行各业的领头羊，他们在事业发展、成绩斐然的今天，以强烈的民族精神和社会责任感，以对家乡、对这片土地深深的热爱情怀，积极投身我省地理标志产业伟大事业中，以实际行动助推地理标志产业发展迎来了新的春天。顺应广大地理标志产品专业社团、经济组织及从事地理标志产业的企业和专业人士的愿望需求,岁月不居，天道酬勤。文化厚重、人杰地灵的山东涌现出了一大批敢为人先、奋发拼搏、勇立潮头的经济社会风云人物和各行各业的领头羊，他们在事业发展、成绩斐然的今天，以强烈的民族精神和社会责任感，以对家乡、对这片土地深深的热爱情怀，积极投身我省地理标志产业伟大事业中，以实际行动助推地理标志产业发展迎来了新的春天。顺应广大地理标志产品专业社团、经济组织及从事地理标志产业的企业和专业人士的愿望需求,
由山东方宇商标事务所有限公司、地理标志产品知名专业社团等多家单位联合发起结成了全省性行业协会社会组织，经省氏政都门依法甲伙协任队L"山东省地理标志产业协会”。协会的成立得到了全省各地地理标志产品生产、经营和关联行业的积极响应和拥护，得到了各级各有关部门、院校、
团体、机构的大力支持和悉心指导。在此，山东地理标志产业协会向关心关注支持的各位领导、各界朋友表示最衷心的感谢!
</div>
<div class="temp_font">扬帆起航的山东省地理标志产业协会，在新时代的召唤下，踏向新征程，奔向灿烂的新明天!</div>
    `*/
  }
}
</script>

<style scoped lang="less">
  @import '~@/assets/css/common.less';
  .messageDiv {
    background-color: #f8f9fa !important;
    padding-bottom: 64px;
  }
  .breadTopDiv {
    text-align: center;
    .breadTop {
      width: 1140px;
      margin: auto;
    }
  }
  .content-div {
    min-height: 481px;
    margin: auto;
    padding: 32px 40px 12px 40px;
    width: 1040px;
    background: #FFFFFF;
    .titleDiv {
      text-align: center;
    }
  }
  .news_title {
    font-size: 32px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #333333;
    line-height: 48px;
  }
  .news_time_reader {
    font-size: 14px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #888888;
    line-height: 21px;
  }
  .news_labels {
    font-size: 16px;
    font-family: SourceHanSansCN-Medium, SourceHanSansCN;
    font-weight: 500;
    color: #3E3A39;
    line-height: 24px;
  }
  .label_contain {
    display: flex;
    align-items: center;
  }
  .label_div {
    background: #E9F2FF;
    border-radius: 6px;
    margin: 5px;
    display: flex;
    float: left;

    .label_span {
      padding: 5px 10px;
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #186CF5;
      line-height: 28px;
    }
  }

  ::v-deep .el-row {
    margin: 20px 0;
    padding: 0 10px;
  }

  ::v-deep .temp_font {
    text-align: left;
    font-size: 16px;
    font-family: SourceHanSansCN-Regular, SourceHanSansCN;
    font-weight: 400;
    color: #3E3A39;
    line-height: 30px;
    text-indent: 2rem;
  }
  p {
    line-height: 1.5;
    letter-spacing: 2px;
  }
  @media screen and (max-width: 1366px) {
    .messageDiv {
      background-color: #f8f9fa !important;
      padding-bottom: 64px;
    }
    .breadTop {
      width: 1140px;
      margin: auto;
    }
    .content-div {
      margin: auto;
      padding: 32px 40px 12px 40px;
      width: 1040px;
      background: #FFFFFF;
      .titleDiv {
        text-align: center;
      }
    }
    .news_title {
      font-size: 32px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #333333;
      line-height: 48px;
    }
    .news_time_reader {
      font-size: 14px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #888888;
      line-height: 21px;
    }
    .news_labels {
      font-size: 16px;
      font-family: SourceHanSansCN-Medium, SourceHanSansCN;
      font-weight: 500;
      color: #3E3A39;
      line-height: 24px;
    }
    .label_contain {
      display: flex;
      align-items: center;
    }
    .label_div {
      background: #E9F2FF;
      border-radius: 6px;
      margin: 5px;
      display: flex;
      float: left;

      .label_span {
        padding: 5px 10px;
        font-size: 14px;
        font-family: SourceHanSansCN-Regular, SourceHanSansCN;
        font-weight: 400;
        color: #186CF5;
        line-height: 28px;
      }
    }

    ::v-deep .el-row {
      margin: 20px 0;
      padding: 0 10px;
    }

    ::v-deep .temp_font {
      text-align: left;
      font-size: 16px;
      font-family: SourceHanSansCN-Regular, SourceHanSansCN;
      font-weight: 400;
      color: #3E3A39;
      line-height: 30px;
      text-indent: 2rem;
    }
    p {
      line-height: 1.5;
      letter-spacing: 2px;
    }
  }
</style>
